import { Target } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";

export default class TableScrollController extends ApplicationController {
  @Target tableContainerTarget!: HTMLElement;
  @Target tableTarget!: HTMLElement;
  @Target scrollbarTarget!: HTMLElement;
  @Target scrollbarContentTarget!: HTMLElement;

  connect() {
    // Set the width of the scrollbar
    this.scrollbarContentTarget.style.width = `${this.tableTarget.offsetWidth}px`;

    // Move the scrollbar when the table moves
    this.tableContainerTarget.addEventListener("scroll", () => {
      this.scrollbarTarget.scroll(this.tableContainerTarget.scrollLeft, 0);
    });

    // Move the table when the scrollbar moves
    this.scrollbarTarget.addEventListener("scroll", () => {
      this.tableContainerTarget.scroll(this.scrollbarTarget.scrollLeft, 0);
    });
  }
}
