import { Target } from "@vytant/stimulus-decorators";
import Stimulus from "../helpers/stimulus";

import ApplicationController from "./application_controller";
import SelectBoxController from "./select_box_controller";
import XhrController from "./xhr_controller";

export default class ScopeLocationsToCountry extends ApplicationController {
  @Target countrySelectTarget!: HTMLSelectElement;
  @Target locationSelectTarget!: HTMLElement;

  xhrController!: XhrController;

  connect() {
    // TODO: Remove the timeout when we move on to using Stimulus Outlets
    // This is needed because the XhrController isn't connected yet,
    // even if `locationSelectTarget` is.
    setTimeout(() => {
      this.xhrController = Stimulus.getController(this.locationSelectTarget, XhrController)!;
      this.scopeLocations();
    }, 0);
  }

  clearLocationsSelect() {
    const controller = Stimulus.getController(this.locationSelectTarget, SelectBoxController)!;
    controller.clearSearchResults();
    controller.clearSearch();
    controller.clearSelection();
  }

  scopeLocations() {
    this.xhrController.paramsValue = {
      ...this.xhrController.paramsValue,
      country_code: this.countrySelectTarget.value,
    };
  }
}
