import flatpickr from "flatpickr";
import { format, parseISO } from "date-fns";
import { Instance } from "flatpickr/dist/types/instance";
import { Target, Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import DropdownMenuController from "./dropdown_menu_controller";
import Stimulus from "../helpers/stimulus";

const dateFormat = "Y-m-d";

export default class DatePickerController extends ApplicationController {
  @Target containerTarget!: HTMLElement;
  @Target displayTarget!: HTMLElement;
  @Target dropdownMenuTarget!: HTMLElement;
  @Target inputTarget!: HTMLInputElement;
  @Value(String) dateValue!: string;
  @Value(String) minDateValue!: string;

  fp!: Instance;

  connect() {
    // The dropdownMenuTarget is teleported after connect() runs, so we need to capture it in a const
    const dropdownMenu = this.dropdownMenuTarget;
    this.fp = flatpickr(this.containerTarget, {
      inline: true,
      minDate: this.minDateValue,
      onReady: (_dates, _dateStr, fp) => {
        if (this.dateValue) {
          fp.setDate(this.dateValue, false);
        }
      },
      onChange: (dates) => {
        this.dateValue = this.fp.formatDate(dates[0], dateFormat);
        Stimulus.getController(dropdownMenu, DropdownMenuController)!.hide();
      },
    });
  }

  dateValueChanged() {
    this.inputTarget.value = this.dateValue;
    this.displayTarget.innerHTML = this.dateValue ? format(parseISO(this.dateValue), "PP") : "Select a date...";
  }
}
