import { guardInstance } from "./util";

// TWMIGRATE: remove twHideClass and use 'hidden' in hideClass once we're off Bootstrap
const hideClass = "d-none";
const twHideClass = "tw:hidden";

type ElementIterable = Array<Element> | NodeListOf<Element> | HTMLCollection;

export function addClass(elements: ElementIterable, className: string) {
  Array.from(elements).forEach((ele) => {
    ele.classList.add(className);
  });
}

export function removeClass(elements: ElementIterable, className: string) {
  Array.from(elements).forEach((ele) => {
    ele.classList.remove(className);
  });
}

export function toggleClass(elements: ElementIterable, className: string) {
  Array.from(elements).forEach((ele) => {
    ele.classList.toggle(className);
  });
}

export function setProperties(elements: ElementIterable, properties: Object) {
  Array.from(elements).forEach((ele) => {
    Object.assign(ele, properties);
  });
}

export function show(elements: ElementIterable) {
  // TWMIGRATE: remove twHideClass line
  removeClass(elements, hideClass);
  removeClass(elements, twHideClass);
}

export function hide(elements: ElementIterable) {
  addClass(elements, hideClass);
  // TWMIGRATE: remove twHideClass line
  addClass(elements, twHideClass);
}

export function toggleDisplay(elements: ElementIterable) {
  toggleClass(elements, hideClass);
  // TWMIGRATE: remove twHideClass line
  toggleClass(elements, twHideClass);
}

export function requestSubmit(form: HTMLFormElement) {
  // taken from: https://github.com/javan/form-request-submit-polyfill/blob/main/form-request-submit-polyfill.js
  // replacement for `HTMLElementrequestSubmit()`, which has poor Safari browser support
  // submits form, fires `submit` event, and triggers browser validations
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/requestSubmit#browser_compatibility
  const submitter = document.createElement("input");
  submitter.type = "submit";
  submitter.hidden = true;
  form.appendChild(submitter);
  submitter.click();
  form.removeChild(submitter);
}

export function autofocus(element: HTMLElement, { preventScroll = false } = {}) {
  element.querySelector<HTMLElement>("[autofocus]")?.focus({ preventScroll });
}

export function traverseElements(root: HTMLElement, callback: { (element: HTMLElement): boolean }) {
  if (!callback.call(null, root)) {
    let childNodes: HTMLCollection | undefined;
    if (root instanceof HTMLTemplateElement) {
      childNodes = root.content.children;
    } else if (root instanceof HTMLIFrameElement) {
      childNodes = root.contentDocument?.children;
    } else {
      childNodes = root.children;
    }
    const childElements = Array.from(childNodes || []).filter(guardInstance(HTMLElement));
    childElements.forEach((child) => {
      traverseElements(child, callback);
    });
  }
}

export function templatizeHtml(html: string) {
  // <template> allows for HTML fragments (e.g. <tr> without a <table>), while DomParser and other elements do not
  const template = document.createElement("template");
  template.innerHTML = html;
  return template;
}

export function cloneElement<T extends Element>(ele: T): T {
  // https://github.com/microsoft/TypeScript/issues/283
  return ele.cloneNode(true) as T;
}

// TODO: get rid of this and move logic into components after full component migration
export function appendStimulusAction(
  ele: HTMLElement,
  event: string,
  action: string,
  params: Record<string, string> = {},
) {
  const eleActions = (ele.dataset.action || "").split(" ");
  ele.dataset.action = [...new Set([...eleActions, `${event}->${action}`])].join(" ");
  Object.keys(params).forEach((k) => {
    ele.setAttribute(`data-${action.split("#")[0]}-${k}-param`, params[k]);
  });
}
