import { Target, Value } from "@vytant/stimulus-decorators";
import Stimulus from "../helpers/stimulus";
import SubmitController from "./submit_controller";
import ApplicationController from "./application_controller";
import { show, hide, removeClass, addClass } from "../helpers/dom";
import handleError from "../helpers/handle_error";

// TWMIGRATE: Remove twInvalidInputClass and add Tailwind class to invalidInputClass
const invalidInputClass = "is-invalid";
const twInvalidInputClass = "tw:border-red-700";
type BeforeSubmitEventDetail = { promises: Array<Promise<any>> };

export default class FormController extends ApplicationController<HTMLFormElement> {
  @Value(Boolean) isSubmittingValue!: boolean;
  // TWMIGRATE: Remove value along with conditionals
  @Value(Boolean) twFormValue!: boolean;
  @Target validationsContainerTarget!: HTMLElement;
  readonly hasValidationsContainerTarget!: boolean;

  submit(event: StimulusEvent) {
    event.preventDefault();
    this.isSubmittingValue = true;
    const beforeSubmitEvent = this.dispatch("beforeSubmit", { detail: { promises: [] } });
    const { promises } = beforeSubmitEvent.detail as BeforeSubmitEventDetail;
    Promise.all(promises)
      .then(() => {
        if (beforeSubmitEvent.defaultPrevented) {
          this.isSubmittingValue = false;
          return;
        }
        this.dispatch("submitting");
      })
      .catch((e) => {
        handleError(e);
        this.isSubmittingValue = false;
      });
  }

  showValidations(event: StimulusEvent) {
    const { errorAttributes } = event.detail.data;
    const errorMessagesHtml = event.detail.html;
    errorAttributes.split(" ").forEach((name: String) => {
      const nameRegex = new RegExp(`\\[${name}\\]`);
      const errorElements = [...this.validateableElements].filter((ele) => ele.name.match(nameRegex));
      // TWMIGRATE: remove conditional and twInvalidInputClass
      if (this.twFormValue) {
        addClass(errorElements, twInvalidInputClass);
      } else {
        addClass(errorElements, invalidInputClass);
      }
    });
    this.validationsContainerTarget.innerHTML = errorMessagesHtml;
    show([this.validationsContainerTarget]);
  }

  clearValidations() {
    // TWMIGRATE: remove conditional and twInvalidInputClass
    if (this.twFormValue) {
      removeClass(this.validateableElements, twInvalidInputClass);
    } else {
      removeClass(this.validateableElements, invalidInputClass);
    }
    hide([this.validationsContainerTarget]);
  }

  resetSubmitting() {
    this.isSubmittingValue = false;
  }

  isSubmittingValueChanged() {
    // Value callbacks trigger before submitControllers are connected
    Promise.resolve().then(() => {
      this.submitControllers.forEach((controller) => {
        controller.isSubmittingValue = this.isSubmittingValue;
      });
    });
  }

  get validateableElements() {
    return this.element.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
      "input[name], select[name], textarea[name]",
    );
  }

  get submitControllers(): Array<SubmitController> {
    return [
      ...this.element.querySelectorAll<HTMLButtonElement>('button[type="submit"]'),
      ...document.querySelectorAll<HTMLButtonElement>(`button[type="submit"][form="${this.element.id}"]`),
    ].map((ele) => Stimulus.getController(ele, SubmitController)!);
  }
}
