import { Target } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import DropdownMenuController from "./dropdown_menu_controller";
import XhrController from "./xhr_controller";
import Stimulus from "../helpers/stimulus";

import { debounce, DebouncedFunc } from "lodash-es";

export default class SelectBoxController extends ApplicationController {
  @Target formInputTarget!: HTMLInputElement;
  @Target dropdownMenuTarget!: HTMLElement;
  @Target searchResultsTarget!: HTMLElement;
  @Target dropdownToggleTarget!: HTMLElement;
  @Target searchTarget!: HTMLInputElement;

  debouncedSearch!: DebouncedFunc<(input: string) => void>;
  xhrController!: XhrController;
  initialDropdownToggleText!: string;

  connect() {
    this.initialDropdownToggleText = this.dropdownToggleTarget.innerHTML;
    this.debouncedSearch = debounce(
      (input) => {
        let xhrController = Stimulus.getController(this.element, XhrController)!;
        xhrController.paramsValue = { ...xhrController.paramsValue, q: input };
        this.dispatch("fetch");
      },
      300,
      { leading: true },
    );
  }

  search(event: StimulusEvent) {
    const input = event.currentTarget as HTMLInputElement;
    if (input.value === "") {
      this.clearSearchResults();
    } else {
      this.debouncedSearch(input.value);
    }
  }

  select(event: StimulusEvent) {
    const option = event.currentTarget;
    const value = option.dataset.value!;
    const displayValue = option.innerHTML;

    this.formInputTarget.value = value;
    this.dropdownToggleTarget.innerHTML = displayValue;
    this.clearSearch();
    this.clearSearchResults();
    this.hideContent();
  }

  clearSearchResults() {
    this.searchResultsTarget.innerHTML = "";
  }

  clearSearch() {
    this.searchTarget.value = "";
  }

  clearSelection() {
    this.formInputTarget.value = "";
    this.dropdownToggleTarget.innerHTML = this.initialDropdownToggleText;
  }

  hideContent() {
    Stimulus.getController(this.dropdownMenuTarget, DropdownMenuController)!.hide();
  }
}
