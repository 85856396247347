import { Placement } from "@floating-ui/dom";
import { Target, Targets } from "@vytant/stimulus-decorators";
import Stimulus from "../helpers/stimulus";
import { appendStimulusAction } from "../helpers/dom";
import ApplicationController from "./application_controller";
import DropdownMenuController from "./dropdown_menu_controller";

export default class DropdownController extends ApplicationController {
  @Targets teleportableDropdownMenuTargets!: Array<HTMLElement>;
  @Target dropdownMenusTeleportTarget!: HTMLElement;

  activeDropdownMenus!: Set<DropdownMenuController>;
  lastToggleEvent?: StimulusEvent;

  initialize() {
    super.initialize();
    this.activeDropdownMenus = new Set();
  }

  connect() {
    // capture ensures that autoClose fires before dropdowns show
    // required so clicks that remotely open dropdowns don't immediately close them
    // e.g. wysiwyg link menu opening link form
    appendStimulusAction(this.element, "click", `${this.identifier}#autoClose:capture`);
    appendStimulusAction(this.element, "dropdown-menu:show", `${this.identifier}#addActiveDropdownMenu`);
    appendStimulusAction(this.element, "dropdown-menu:hide", `${this.identifier}#removeActiveDropdownMenu`);
    appendStimulusAction(this.element, "keydown.esc", `${this.identifier}#hideAll`);
  }

  teleportableDropdownMenuTargetConnected(element: HTMLElement) {
    // setTimeouit prevents a race condition caused by dropdownMenusTeleportTarget being unavailable as it's lower in the DOM
    setTimeout(() => {
      if (element.parentElement === this.dropdownMenusTeleportTarget) return;

      this.dropdownMenusTeleportTarget.appendChild(element);
    }, 0);
  }

  // added `disableToggleAria` because adding attributes to anchor node can be undesirable
  // especially in nodes from the ProseMirror/TipTap editor where attribute changes trigger a refresh and remove the node from the DOM
  toggle(event: StimulusEvent<{ placement: Placement; menuId: number; disableToggleAria: boolean }>) {
    event.preventDefault();
    // prevent multiple toggles with a single click (e.g. overlapping highlighted terms)
    if (event === this.lastToggleEvent) return;
    const anchor = event.currentTarget;
    const { placement, menuId, disableToggleAria } = event.params;
    const dropdownMenuElement = this.propSelector("id", menuId);
    const dropdownMenu = Stimulus.getController(dropdownMenuElement, DropdownMenuController)!;
    if (dropdownMenu.isActive) {
      dropdownMenu.hide();
      if (!disableToggleAria) anchor.setAttribute("aria-expanded", "false");
    } else {
      dropdownMenu.show(anchor, { placement });
      if (!disableToggleAria) anchor.setAttribute("aria-expanded", "true");
    }
    this.lastToggleEvent = event;
  }

  addActiveDropdownMenu(event: StimulusEvent) {
    const dropdownMenu = Stimulus.getController(event.target, DropdownMenuController)!;
    this.activeDropdownMenus.add(dropdownMenu);
  }

  removeActiveDropdownMenu(event: StimulusEvent) {
    const dropdownMenu = Stimulus.getController(event.target, DropdownMenuController)!;
    this.activeDropdownMenus.delete(dropdownMenu);
  }

  autoClose(event: StimulusEvent) {
    this.hideIf(
      (dropdownMenu) => !dropdownMenu.anchor?.contains(event.target) && !dropdownMenu.element.contains(event.target),
    );
  }

  hideAll() {
    this.hideIf(() => true);
  }

  private hideIf(callback: (dropdownMenu: DropdownMenuController) => boolean) {
    this.activeDropdownMenus.forEach((dropdownMenu) => {
      if (callback === undefined || callback(dropdownMenu)) {
        dropdownMenu.hide();
      }
    });
  }
}
